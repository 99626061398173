import { v4 as uuidv4 } from 'uuid';
import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cartItems: [],
        appliedCoupon: null,
        discountedTotal: 0
    },
    reducers: {
        setCartItems(state, action) {
            state.cartItems = action.payload;
        },
        addToCart(state, action) {
            const product = action.payload;
            if(!product.variation){
                const cartItem = state.cartItems.find(item => item.cartId === product._id);
                if(!cartItem){
                    state.cartItems.push({
                        ...product,
                        quantity: product.quantity ? product.quantity : 1,
                        cartItemId: uuidv4()
                    });
                } else {
                    state.cartItems = state.cartItems.map(item => {
                        if(item.cartItemId === cartItem.cartId){
                            return {
                                ...item,
                                quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1,
                                qty: product.qty ? item.qty + product.qty : item.qty + 1
                            }
                        }
                        return item;
                    })
                }

            } else {
                const cartItem = state.cartItems.find(
                    item =>
                        item.cartId === product.id &&
                        product.selectedProductColor &&
                        product.selectedProductColor === item.selectedProductColor &&
                        product.selectedProductSize &&
                        product.selectedProductSize === item.selectedProductSize &&
                        (product.cartItemId ? product.cartItemId === item.cartItemId : true)
                );
                if(!cartItem){
                    state.cartItems.push({
                        ...product,
                        quantity: product.quantity ? product.quantity : 1,
                        // cartItemId: uuidv4()
                        cartItemId: product._id
                    });
                } else if (cartItem !== undefined && (cartItem.selectedProductColor !== product.selectedProductColor || cartItem.selectedProductSize !== product.selectedProductSize)) {
                    state.cartItems = [
                        ...state.cartItems,
                        {
                            ...product,
                            quantity: product.quantity ? product.quantity : 1,
                            cartItemId: product._id
                            // cartItemId: uuidv4()
                        }
                    ]
                } else {
                    state.cartItems = state.cartItems.map(item => {
                        if(item.cartItemId === cartItem.cartItemId){
                            return {
                                // ...product,
                                ...item,
                                quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1,
                                selectedProductColor: product.selectedProductColor,
                                selectedProductSize: product.selectedProductSize
                            }
                        }
                        return item;
                    });
                }
            }

            cogoToast.success("Added To Bag", {position: "bottom-left"});
        },
        deleteFromCart(state, action) {
            // state.cartItems = state.cartItems.filter(item => item.cartItemId !== action.payload);
            state.cartItems = state.cartItems.filter(item => item._id !== action.payload);
            cogoToast.error("Removed From Bag", {position: "bottom-left"});
        },
        decreaseQuantity(state, action){
            const product = action.payload;
            if (product.quantity === 1) {
                state.cartItems = state.cartItems.filter(item => item._id !== product._id);
                // state.cartItems = state.cartItems.filter(item => item.cartItemId !== product.cartItemId);
                // cogoToast.error("Removed From Cart", {position: "bottom-left"});
            } else {
                state.cartItems = state.cartItems.map(item =>{
                    if (item._id === product._id) {
                        if (item.qty > 0) {
                          return { ...item, quantity: item.quantity - 1, qty: item.qty - 1 };
                        }
                      }
                      return item;
                    }
                    // item._id === product._id
                    //     ? { ...item, quantity: item.quantity - 1, qty: item.qty - 1 }
                    //     : item
                );
                cogoToast.warn("Item Decremented From Bag", {position: "bottom-left"});
            }
        },
        deleteAllFromCart(state){
            state.cartItems = []
        },
        // applyCoupon(state, action) {
        //     const { coupon, cartTotal } = action.payload;
        //     state.appliedCoupon = coupon;
            
        //     if (coupon.perc) {
        //       state.discountedTotal = cartTotal * (1 - coupon.perc / 100);
        //     } else if (coupon.amt) {
        //       state.discountedTotal = Math.max(cartTotal - coupon.amt, 0);
        //     }
        //   },
        //   removeCoupon(state) {
        //     state.appliedCoupon = null;
        //     state.discountedTotal = undefined;
        //   }
        applyCoupon(state, action) {
            const { coupon, cartTotal } = action.payload;
            if (cartTotal >= coupon.minAmt) {
                state.appliedCoupon = coupon;
                if (coupon.perc) {
                    state.discountedTotal = cartTotal * (1 - coupon.perc / 100);
                } else if (coupon.amt) {
                    state.discountedTotal = Math.max(cartTotal - coupon.amt, 0);
                }
                cogoToast.success("Coupon applied successfully", {position: "bottom-left"});
            } else {
                state.appliedCoupon = null;
                state.discountedTotal = cartTotal;
                cogoToast.error("Minimum amount not reached for this coupon", {position: "bottom-left"});
            }
        },

        removeCoupon(state) {
            state.appliedCoupon = null;
            state.discountedTotal = 0;
        }
    },
});

export const { setCartItems, addToCart, deleteFromCart, decreaseQuantity, deleteAllFromCart, applyCoupon,
    removeCoupon } = cartSlice.actions;
export default cartSlice.reducer;
