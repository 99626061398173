import cogoToast from 'cogo-toast';
import axiosInstance from '../../axios';
import { ADD_ORDERS, GET_ORDERS } from '../types';
import Swal from 'sweetalert2';


export const getOrders = () => async (dispatch) => {
    try {
      const res = await axiosInstance.get("order/get");
      if (res.data.code === 1) {
        // dispatch(setProd(res.data.data));
        console.log("order/get :",res.data.data);      
        dispatch({ type: GET_ORDERS, payload: res.data.data });
      } else {
        // Handle case where the code is not 1 if needed
        console.error('Unexpected response code in orders:', res.data.code);
      }
    } catch (err) {
      console.error('Failed to fetch orders:', err);
    }
  };


// Add Order
export const addOrder = (formData) => async (dispatch) => {
    try {
      const res = await axiosInstance.post("order/add", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (res.data.code === 1) {
        await dispatch({ type: ADD_ORDERS, payload: res.data.data });
        Swal.fire({
          title: 'Success!',
          text: 'You are in Process. Wait for Admin Confirmation',
          icon: 'success',
        });
      } 
      if (res.data.code === 0) {    
        Swal.fire({
          title: 'Error!',
          text: res.data.message,
          icon: 'error',
        });
      } else {        
        Swal.fire({
          title: 'Success!',
          text: res.data.message,
          icon: 'success',
        });
      }
    } catch (err) {
      console.error('Unexpected response code:',err.message);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to Order.',
        icon: 'error',
      });
    }
  };