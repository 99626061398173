import React, { useState, useEffect } from 'react'
import clsx from "clsx";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoHomeOutline, IoNotificationsOutline, IoPersonOutline } from 'react-icons/io5';
import { IoCallOutline, IoBagOutline } from "react-icons/io5";

function MobileViewFooter() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("userauth");

        if (token) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, []);
    return (
        <>
            <div className="footer d-flex justify-content-around align-items-center">
                <div className="footer-item text-center">
                    <Link to={process.env.PUBLIC_URL + "/"}><IoHomeOutline size={24} />
                        <p>Home</p></Link>
                </div>
                <div className="footer-item text-center">
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                        <IoBagOutline size={24} />
                        <p>Shop</p>
                    </Link>
                </div>
                <div className="footer-item text-center">
                    <Link to={process.env.PUBLIC_URL + "/about"}><IoCallOutline size={24} />
                        <p>Contact Us</p></Link>
                </div>
                <div className="footer-item text-center">
                    {
                        isAuthenticated ? (
                            <Link to={process.env.PUBLIC_URL + "/myaccount"}>
                                <IoPersonOutline size={24} />
                                <p>Profile</p>
                            </Link>
                        ) : (
                            <Link to={process.env.PUBLIC_URL + "/login-register"}>
                                <IoPersonOutline size={24} />
                                <p>Profile</p>
                            </Link>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default MobileViewFooter