import {
  UPDATE_PROFILE_DATA,
  GET_CUSTOMER
} from "../types";

const initialState = { list: [], single: null };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
          case GET_CUSTOMER:
            return { ...state, list: payload };
  
            case UPDATE_PROFILE_DATA:
              return {
                  ...state,
                  single: payload
              };
        default:
            return state;
    }
};

export default reducer;