import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { EffectFade, Thumbs } from 'swiper';
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Rating from "./sub-components/ProductRating";
import Swiper, { SwiperSlide } from "../swiper";
import { getProductCartQuantity } from "../../helpers/product";
// import { addToCart } from "../../store/slices/cart-slice";
import { addToWishlist } from "../../store/slices/wishlist-slice";
import { addToCompare } from "../../store/slices/compare-slice";
import { useNavigate } from "react-router-dom";
import { addCart } from "../../store/actions/cart-actions";

function BestProductModal({ product, bestprod, currency, discountedPrice, finalProductPrice, finalDiscountedPrice, show, onHide, wishlistItem, compareItem }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);
  // const isLoggedIn = useSelector((state) => state.user.user !== null);
  const isLoggedIn = localStorage.getItem("userauth");
  const [selectedProductColor, setSelectedProductColor] = useState(product.color);
  // const productStock = 1;
  const [productStock, setProductStock] = useState(product.status);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate(); 
  
// console.log("product. :", product);
// console.log("product.image :", product.images[0]);

  // const [selectedProductColor, setSelectedProductColor] = useState(
  //   product.color : ""
  // );
  // const [selectedProductSize, setSelectedProductSize] = useState(
  //   product.size[0].name : ""
  // );
  // const [productStock, setProductStock] = useState(
  //   product.size[0].stock : product.stock
  // );
  const [quantityCount, setQuantityCount] = useState(1);
  const productCartQty = getProductCartQuantity(
    cartItems,
    product,
    // bestprod,
    selectedProductColor,
    // selectedProductSize
  );


  const gallerySwiperParams = {
    spaceBetween: 10,
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true
    },
    thumbs: { swiper: thumbsSwiper },
    modules: [EffectFade, Thumbs],
  };

  const thumbnailSwiperParams = {
    onSwiper: setThumbsSwiper,
    spaceBetween: 10,
    slidesPerView: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: true,
    slideToClickedSlide: true,
    navigation: true
  };

  const onCloseModal = () => {
    setThumbsSwiper(null)
    onHide()
  }

  // const handleAddToCart = () => {
  //   // if (!isLoggedIn) {
  //   //   navigate("/login-register"); // Redirect to login if not logged in
  //   //   return;
  //   // }
  //   // console.log("here modal");
    
  //   // Create the formData object with the necessary fields
  //   const formData = {
  //     productId: product._id, // Use the correct product ID
  //   };
    
  //   // dispatch(addToCart(formData)); 
  //   dispatch(addCart(formData)); 
  //   window.location.reload();
  // };

  return (
    <Modal show={show} onHide={onCloseModal} className="product-quickview-modal-wrapper">
    <Modal.Header closeButton></Modal.Header>

    <div className="modal-body">
      <div className="row">
        <div className="col-md-5 col-sm-12 col-xs-12">
          <div className="product-large-image-wrapper">
            <Swiper options={gallerySwiperParams}>
              {product.images &&
                product.images.map((img, i) => {               
                  return (
                    <SwiperSlide key={i}>
                      <div className="single-image">
                        <img
                          src={img.src}
                          className="img-fluid"
                          alt="Product"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
          <div className="product-small-image-wrapper mt-15">
            <Swiper options={thumbnailSwiperParams}>
              {product.image &&
                product.image.map((img, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="single-image">
                        <img
                          src={img.src}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
        <div className="col-md-7 col-sm-12 col-xs-12">
          <div className="product-details-content quickview-content">
            <h2>{product.name}</h2>
            <div className="product-details-price">
              {/* {discountedPrice !== null ? (
                <Fragment>
                  <span>
                    {"₹ "+finalDiscountedPrice}
                  </span>{" "}
                  <span className="old">
                    {"₹ "+finalProductPrice}
                  </span>
                </Fragment>
              ) : (
                <span>{"₹ "+finalProductPrice} </span>
              )} */}
                <span>
                    {"₹ "+product.mrp}
                  </span>{" "}
                  <span className="old">
                  {"₹ "+product.price}
                </span>
            </div>
            {/* {bestprod.rating && bestprod.rating > 0 ? (
              <div className="pro-details-rating-wrap">
                <div className="pro-details-rating">
                  <Rating ratingValue={bestprod.rating} />
                </div>
              </div>
            ) : (
              ""
            )} */}
            <div className="pro-details-list">
                {/* <p>{(product.about !== "undefined" && product.about !== "") ? product.about : "No data Available"}</p> */}
                <p dangerouslySetInnerHTML={{ __html: product.about !== "undefined" && product.about !== "" ? product.about : "No data Available" }} />

            </div>

            {/* {bestprod.variation ? ( */}
              <div className="pro-details-size-color">
                <div className="pro-details-color-wrap">
                  <span>Color</span>
                  <div className="pro-details-color-content">
                    {/* {bestprod.variation.map((single, key) => { */}
                     
                        <label
                          className={`pro-details-color-content--single ${product.color}`}
                          // key={key}
                        >
                          <input
                            type="radio"
                            value={product.color}
                            name="product-color"
                            checked={
                              product.color === selectedProductColor
                                ? "checked"
                                : ""
                            }
                            onChange={() => {
                              setSelectedProductColor(product.color);
                              // setSelectedProductSize(single.size[0].name);
                              // setProductStock(single.size[0].stock);
                              setQuantityCount(1);
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                    
                    {/* })} */}
                  </div>
                </div>
                {/* <div className="pro-details-size">
                  <span>Size</span>
                  <div className="pro-details-size-content">
                    {product.variation &&
                      product.variation.map(single => {
                        return single.color === selectedProductColor
                          ? single.size.map((singleSize, key) => {
                              return (
                                <label
                                  className={`pro-details-size-content--single`}
                                  key={key}
                                >
                                  <input
                                    type="radio"
                                    value={singleSize.name}
                                    // checked={
                                    //   singleSize.name ===
                                    //   selectedProductSize
                                    //     ? "checked"
                                    //     : ""
                                    // }
                                    onChange={() => {
                                      // setSelectedProductSize(
                                      //   singleSize.name
                                      // );
                                      // setProductStock(singleSize.stock);
                                      setQuantityCount(1);
                                    }}
                                  />
                                  <span className="size-name">
                                    {singleSize.name}
                                  </span>
                                </label>
                              );
                            })
                          : "";
                      })}
                  </div>
                </div> */}
              </div>
            {/* ) : ( */}
              {/* "" */}
            {/* )} */}
            {/* {product.affiliateLink ? ( */}
              <div className="pro-details-quality">
                <div className="pro-details-cart btn-hover">
                  {/* <a
                    href={product.affiliateLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  > */}
                    {/* Buy Now */}
                  {/* </a> */}
                </div>
              </div>
            {/* ) : ( */}
              <div className="pro-details-quality">
                {/* <div className="cart-plus-minus">
                  <button
                    onClick={() =>
                      setQuantityCount(
                        quantityCount > 1 ? quantityCount - 1 : 1
                      )
                    }
                    className="dec qtybutton"
                  >
                    -
                  </button>
                  <input
                    className="cart-plus-minus-box"
                    type="text"
                    value={quantityCount}
                    readOnly
                  />
                  <button
                    onClick={() =>
                      setQuantityCount(
                        quantityCount < productStock - productCartQty
                          ? quantityCount + 1
                          : quantityCount
                      )
                    }
                    className="inc qtybutton"
                  >
                    +
                  </button>
                </div> */}
                <div className="pro-details-cart btn-hover">
                  {productStock && productStock > 0 ? (
                    <button
                      // onClick={() =>
                      //   dispatch(addToCart({
                      //     ...product,
                      //     quantity: quantityCount,
                      //     selectedProductColor: selectedProductColor ? selectedProductColor : product.selectedProductColor ? product.selectedProductColor : null,
                      //     // selectedProductSize: selectedProductSize ? selectedProductSize : product.selectedProductSize ? product.selectedProductSize : null
                      //   }))
                      // }
                      // onClick={handleAddToCart}
                      onClick={() => {
                        navigate(`/product/${product._id}`); // Replace with your route
                      }}
                      // style={{borderRadius:"2vh"}}
                      disabled={productCartQty >= productStock}
                    >
                      {" "}
                      Show Full Description{" "}
                    </button>
                  ) : (
                    <button disabled>Out of Stock</button>
                  )}
                </div>
                <div className="pro-details-wishlist">
                  <button
                    className={wishlistItem !== undefined ? "active" : ""}
                    disabled={wishlistItem !== undefined}
                    title={
                      wishlistItem !== undefined
                        ? "Added to wishlist"
                        : "Add to wishlist"
                    }
                    onClick={() => dispatch(addToWishlist(product))}
                  >
                    <i className="pe-7s-like" />
                  </button>
                </div>
                <div className="pro-details-compare">
                  <button
                    className={compareItem !== undefined ? "active" : ""}
                    disabled={compareItem !== undefined}
                    title={
                      compareItem !== undefined
                        ? "Added to compare"
                        : "Add to compare"
                    }
                    onClick={() => dispatch(addToCompare(product))}
                  >
                    <i className="pe-7s-shuffle" />
                  </button>
                </div>
              </div>
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  </Modal>
  );
}

BestProductModal.propTypes = {
  currency: PropTypes.shape({}),
  discountedprice: PropTypes.number,
  finaldiscountedprice: PropTypes.number,
  finalproductprice: PropTypes.number,
  onHide: PropTypes.func,
  product: PropTypes.shape({}),
  show: PropTypes.bool,
  wishlistItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({})
};

export default BestProductModal;
