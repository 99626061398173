import {
  GET_BRANDS,
} from "../types";

const initialState = { list: [], single: null };

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BRANDS:
      return { ...state, list: payload };

    default:
      return state;
  }
};

export default reducer;
