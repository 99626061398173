import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { SET_FILTER } from "../../store/types";
import { getNewFilterdProducts } from "../../store/actions/product-actions";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const ShopPriceRange = ({
  priceRange,
  getSortParams,
  selectedMinPrice,
  selectedMaxPrice,
  currentFilters
}) => {
  const dispatch = useDispatch();
  const [minPrice, setMinPrice] = useState(selectedMinPrice || priceRange.min);
  const [maxPrice, setMaxPrice] = useState(selectedMaxPrice || priceRange.max);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setMinPrice(selectedMinPrice || priceRange.min);
    setMaxPrice(selectedMaxPrice || priceRange.max);
  }, [selectedMinPrice, selectedMaxPrice, priceRange]);

  const handlePriceChange = (value) => {
    const [newMin, newMax] = value;

    setMinPrice(newMin);
    setMaxPrice(newMax);

    dispatch({
      type: SET_FILTER,
      payload: {
        filterType: "minPrice",
        value: newMin,
      },
    });
    dispatch({
      type: SET_FILTER,
      payload: {
        filterType: "maxPrice",
        value: newMax,
      },
    });

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      dispatch(
        getNewFilterdProducts({
          page: 1,
          limit: 15,
          minPrice: newMin,
          maxPrice: newMax,
          category: currentFilters.category,
          modelId: currentFilters.modelId,
          // brandId: currentFilters.brandId,
          brands: currentFilters.brands,
          storage: currentFilters.storage,
          ram: currentFilters.ram,
          // disPer: currentFilters.discount,
          color: currentFilters.color,
        })
      );
    }, 300);
  };

  const handleInputChange = (type, value) => {
    const numberValue = Math.max(
      priceRange.min,
      Math.min(Number(value), priceRange.max)
    );

    if (type === "min") {
      setMinPrice(numberValue);
      handlePriceChange([numberValue, maxPrice]);
    } else {
      setMaxPrice(numberValue);
      handlePriceChange([minPrice, numberValue]);
    }
  };

  return (
    <div className="sidebar-widget mt-50">
      <h4 className="pro-sidebar-title">Price Range</h4>
      <div className="price-filter mt-25">
        <div
          className="price-slider-amount"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <label style={{ fontWeight: "bold" }}>Min Price:</label>
            <span>₹</span>
            <input
              type="number"
              value={minPrice}
              onChange={(e) => handleInputChange("min", e.target.value)}
              min={priceRange.min}
              max={maxPrice}
              disabled
              style={{
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                width: "100%",
                textAlign: "right",
              }}
            />
          </div>

          <RangeSlider
            id="range-slider-gradient"
            className="margin-lg"
            min={priceRange.min}
            max={priceRange.max}
            step={1}
            value={[minPrice, maxPrice]}
            onInput={handlePriceChange}
            style={{ width: "100%" }} // Ensure it spans the full width
          />

          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <label style={{ fontWeight: "bold" }}>Max Price:</label>
            <span>₹</span>
            <input
              type="number"
              value={maxPrice}
              onChange={(e) => handleInputChange("max", e.target.value)}
              min={minPrice}
              disabled
              max={priceRange.max}
              style={{
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                width: "100%",
                textAlign: "right",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ShopPriceRange.propTypes = {
  priceRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  getSortParams: PropTypes.func.isRequired,
  selectedMinPrice: PropTypes.number,
  selectedMaxPrice: PropTypes.number,
};

export default ShopPriceRange;
