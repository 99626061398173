import axiosInstance from '../../axios';
import { addToCart, deleteFromCart, setCartItems } from '../slices/cart-slice';
import { ADD_CART, DELETE_CART, GET_CART } from '../types';
import Swal from 'sweetalert2';

export const getCartProducts = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("cart/get");
    if (res.data.code === 1) {
      // dispatch(setProd(res.data.data));
      console.log("cart/get :",res.data.data.items);      
      dispatch(setCartItems(res.data.data.items));
      dispatch({ type: GET_CART, payload: res.data.data });
    } else {
      // Handle case where the code is not 1 if needed
      console.error('Unexpected response code:', res.data.code);
    }
  } catch (err) {
    console.error('Failed to fetch cart:', err);
  }
};

// Add Cart
export const addCart = (formData) => async (dispatch) => {
  try {
    const res = await axiosInstance.post("cart/add", formData);
    if (res.data.code === 1) {
      await dispatch(addToCart(res.data.data))
      await dispatch({ type: ADD_CART, payload: res.data.data });
      Swal.fire({
        title: 'Success!',
        text: 'Item added to cart successfully.',
        icon: 'success',
      });
    } else {
      console.error('Unexpected response code:', res.data.message);
      Swal.fire({
        title: 'Error!',
        text: res.data.message,
        icon: 'error',
      });
    }
  } catch (err) {
    console.error('Unexpected response code:',err.message);
    // Swal.fire({
    //   title: 'Error!',
    //   text: 'Failed to add item to cart.',
    //   icon: 'error',
    // });
  }
};

// Delete Cart
export const deleteCart = (id) => async (dispatch) => {
  try {
    if(!id){
      // dispatch(setAlert('Please select any record', 'warning'));
      Swal.fire({
        title: 'Warning!',
        text: 'Please select any record.',
        icon: 'warning',
      });
      return false;
    }
    const res = await axiosInstance.post(`cart/delete/${id}`);
    if (res.data.code === 1) {
        dispatch(deleteFromCart(id))
        dispatch({ type: DELETE_CART, payload: {id} });
        Swal.fire({
          title: 'Success!',
          text: res.data.message,
          icon: 'success',
        });
      } else {
        Swal.fire({
          title: 'Warning!',
          text: res.data.message,
          icon: 'warning',
        });
      }
  } catch (err) {
    console.log("cart delete error :", err);
    
    // Swal.fire({
    //   title: 'Error!',
    //   text: err.message,
    //   icon: 'error',
    // });
  }
};