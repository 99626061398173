// import axios from '../../axios';
import axiosInstance from '../../axios';
import { setFilteredProducts, setOneProd, setProd } from '../slices/product-slice';
import { FILTERD_PRODUCTS, GET_ONE_PRODUCTS, GET_PRODUCTS, GET_MOBILES } from '../types';

export const getNewProducts = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("prod/get?page=1&limit=8&status=1&isVisible=1");
    if (res.data.code === 1) {
      dispatch(setProd(res.data.data));
      // dispatch(setFilteredProducts(res.data.data))
      dispatch({ type: GET_PRODUCTS, payload: res.data.data });
    } else {
      // Handle case where the code is not 1 if needed
      console.error('Unexpected response code:', res.data.code);
    }
  } catch (err) {
    console.error('Failed to fetch banners:', err.message);
  }
};

export const getMobileCategories = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("prod/get?page=1&limit=12&status=1&isVisible=1&category=Mobile");
    if (res.data.code === 1) {
      // dispatch(setProd(res.data.data));
      // dispatch(setFilteredProducts(res.data.data))
      dispatch({ type: GET_MOBILES, payload: res.data.data });
    } else {
      // Handle case where the code is not 1 if needed
      console.error('Unexpected response code:', res.data.code);
    }
  } catch (err) {
    console.error('Failed to fetch banners:', err.message);
  }
};

export const getProduct = (id) => async (dispatch) => {
  try {
    console.log("id_+_______________________", id);
    
    // const res = await axios.get(`prod/get?id=${id}&status=1&isVisible=1`);
    const res = await axiosInstance.get(`prod/get?id=${id}&status=1&isVisible=1`);
    // const res = await axios.get("prod/get");
    if (res.data.code === 1) {
      console.log("fetched one product :", res.data.data);
      
      dispatch({ type: GET_ONE_PRODUCTS, payload: res.data.data });
      dispatch(setOneProd(res.data.data));
    }
  } catch (err) {
    console.log(err.message);
    console.error('Failed to fetch product:', err.message);
  }
};
// export const getProduct = (id) => async (dispatch) => {
//   try {
//     const res = await axios.get(`prod/get?id=${id}&status=1&isVisible=1`);
//     console.log("getProduct id :", id);
    
//     if (res.data.code === 1) {
//       dispatch(setOneProd(res.data.data));
//     } else {
//       console.error('Unexpected response code:', res.data.code);
//     }
//   } catch (err) {
//     console.error('Failed to fetch product:', err);
//   }
// };

export const getNewFilterdProducts = (filters) => async (dispatch) => {
  try {
    const queryParams = new URLSearchParams();
    
    // Add base params
    queryParams.append('page', filters.page || 1);
    queryParams.append('limit', filters.limit || 15);
    queryParams.append('status', 1);
    queryParams.append('isVisible', 1);

    // Add optional filters
    const filterParams = {
      // brandId: filters.brandId,
      brands: filters.brands,
      modelId: filters.modelId,
      category: filters.category,
      disPer: filters.disPer,
      color: filters.color,
      ram: filters.ram,
      storage: filters.storage,
      minPrice: filters.minPrice,
      maxPrice: filters.maxPrice
    };

    // Add non-empty filters to query params
    Object.entries(filterParams).forEach(([key, value]) => {
      if (value) queryParams.append(key, value);
    });

    const url = `prod/get?${queryParams.toString()}`;
    const res = await axiosInstance.get(url);

    if (res.data.code === 1) {
      dispatch({ type: FILTERD_PRODUCTS, payload: res.data.data });
      
      // Update URL without page reload
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    } else {
      console.error('Unexpected response code:', res.data.code);
    }
  } catch (err) {
    console.error('Failed to fetch products:', err);
  }
};

// export const getNewFilterdProducts = ({
//   page,
//   limit,
//   brandId,
//   modelId,
//   category,
//   disPer,
//   color,
//   minPrice,
//   maxPrice
// }) => async (dispatch) => {
//   try {
//     // Construct URL with dynamic query parameters
    
//     const url = `prod/get?page=${page}&limit=${limit}&status=1&isVisible=1` +
//                 `${brandId ? `&brandId=${brandId}` : ''}` +
//                 `${modelId ? `&modelId=${modelId}` : ''}` +
//                 `${category ? `&category=${category}` : ''}` +
//                 `${disPer ? `&disPer=${disPer}` : ''}` +
//                 `${color ? `&color=${color}` : ''}` +
//                 `${minPrice ? `&minPrice=${minPrice}` : ''}` +
//                 `${maxPrice ? `&maxPrice=${maxPrice}` : ''}`;

//     const res = await axiosInstance.get(url);
//     if (res.data.code === 1) {
//       dispatch({ type: FILTERD_PRODUCTS, payload: res.data.data });
//       // dispatch(setProd(res.data.data));
//       // dispatch(setFilteredProducts(res.data.data));
//     } else {
//       console.error('Unexpected response code:', res.data.code);
//     }
//   } catch (err) {
//     console.error('Failed to fetch products:', err);
//   }
// };


