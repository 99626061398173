import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SET_FILTER } from '../../store/types';
import { getNewFilterdProducts } from '../../store/actions/product-actions';

const ShopStorage = ({ storages }) => {
  const dispatch = useDispatch();
  const currentFilters = useSelector(state => state.products.filter);

  const handleStorageClick = (storage) => {
    // Update array of selected storage values
    const updatedStorages = currentFilters.storage.includes(storage)
      ? currentFilters.storage.filter(s => s !== storage) // Remove if exists
      : [...currentFilters.storage, storage]; // Add if doesn't exist

    // Update the filter in Redux
    dispatch({
      type: SET_FILTER,
      payload: {
        filterType: 'storage',
        value: updatedStorages
      }
    });
    
    // Fetch filtered products with updated storage selection
    dispatch(getNewFilterdProducts({
      ...currentFilters,
      page: 1,
      limit: 15,
      storage: updatedStorages // Use the updated storage array
    }));
  };

  const handleClearStorages = () => {
    dispatch({
      type: SET_FILTER,
      payload: {
        filterType: 'storage',
        value: []
      }
    });

    dispatch(getNewFilterdProducts({
      ...currentFilters,
      page: 1,
      limit: 15,
      storage: [] // Clear storage filter
    }));
  };

  return (
    <div className="sidebar-widget mt-50">
      <h4 className="pro-sidebar-title">Storage</h4>
      <div className="sidebar-widget-list mt-20">
        {storages ? (
          <ul>
            <li>
              <div className="sidebar-widget-list-left">
                <button
                  className={currentFilters.storage.length === 0 ? 'active' : ''}
                  onClick={handleClearStorages}
                >
                  <span className="checkmark" /> All storages
                </button>
              </div>
            </li>
            {storages.map((storage, key) => (
              <li key={key}>
                <div className="sidebar-widget-list-left">
                  <button
                    className={currentFilters.storage.includes(storage) ? 'active' : ''}
                    onClick={() => handleStorageClick(storage)}
                  >
                    <span className="checkmark" /> {storage}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          'No storages found'
        )}
      </div>
    </div>
  );
};

ShopStorage.propTypes = {
  storages: PropTypes.array.isRequired,
};

export default ShopStorage;