import React, { useEffect, useMemo, useState } from "react"; 
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import ShopColor from "../../components/product/ShopColor";
import ShopRam from "../../components/product/ShopRam";
import ShopStorage from "../../components/product/ShopStorage";
import MyShopCategory from "../../components/product/MyShopCategory";
import ShopTag from "../../components/product/ShopTag";
import ShopPriceRange from "../../components/product/ShopPriceRange";
import { getBrands } from "../../store/actions/brand";
import { setFilteredProducts } from "../../store/slices/product-slice";
import { getNewFilterdProducts } from "../../store/actions/product-actions";
import { SET_FILTER } from "../../store/types";
import { getModels } from "../../store/actions/model";

const MyShopSidebar = ({ sideSpaceClass }) => {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.products.list);
  const currentFilters = useSelector((state) => state.products.filter);
  const brands = useSelector((state) => state.brands.list);
  const models = useSelector((state) => state.models.list);

  const [prevFilters, setPrevFilters] = useState(currentFilters);

  const uniqueColors = useMemo(() => getMyIndividualColors(productList), [productList]);
  const uniqueRams = useMemo(() => getMyIndividualRams(productList), [productList]);
  const uniqueStorages = useMemo(() => getMyIndividualStorage(productList), [productList]);
  const uniqueCategory = useMemo(() => getMyIndividualCategory(productList), [productList]);
  // const uniqueBrands = useMemo(() => getMyIndividualBrands(productList), [productList]); // Adjusted to brands
  const uniqueDiscount = useMemo(() => getMyIndividualDiscount(productList), [productList]);
  const priceRange = useMemo(() => getMinMaxPrice(productList), [productList]); 

  useEffect(() => {
    dispatch(getNewFilterdProducts({
      page: 1,
      limit: 15
    }));
    dispatch(getBrands())
    dispatch(getModels())
  }, [dispatch])

  useEffect(() => {
    // Initialize filters from URL on component mount
    const params = new URLSearchParams(window.location.search);
    const urlFilters = {};

    const brandsParam = params.get('brands');
    if (brandsParam) {
      urlFilters.brands = brandsParam.split(',');
      dispatch({
        type: SET_FILTER,
        payload: { filterType: 'brands', value: urlFilters.brands }
      });
    }
    
    const modelsParam = params.get('modelId');
    if (modelsParam) {
      urlFilters.modelId = modelsParam.split(',');
      dispatch({
        type: SET_FILTER,
        payload: { filterType: 'modelId', value: urlFilters.modelId }
      });
    }
    
    const categoriesParam = params.get('category');
    if (categoriesParam) {
      urlFilters.category = categoriesParam.split(',');
      dispatch({
        type: SET_FILTER,
        payload: { filterType: 'category', value: urlFilters.category }
      });
    }
    
    const colorParam = params.get('color');
    if (colorParam) {
      urlFilters.color = colorParam.split(',');
      dispatch({
        type: SET_FILTER,
        payload: { filterType: 'color', value: urlFilters.color }
      });
    }
    
    const ramParam = params.get('ram');
    if (ramParam) {
      urlFilters.ram = ramParam.split(',');
      dispatch({
        type: SET_FILTER,
        payload: { filterType: 'ram', value: urlFilters.ram }
      });
    }
    
    const storageParam = params.get('storage');
    if (storageParam) {
      urlFilters.storage = storageParam.split(',');
      dispatch({
        type: SET_FILTER,
        payload: { filterType: 'storage', value: urlFilters.storage }
      });
    }
    
    ['minPrice', 'maxPrice', 'disPer'].forEach(param => {
      const value = params.get(param);
      if (value) {
        urlFilters[param] = value;
        dispatch({
          type: SET_FILTER,
          payload: { filterType: param, value }
        });
      }
    });

    // Only fetch if there are URL filters
    if (Object.keys(urlFilters).length > 0) {
      dispatch(getNewFilterdProducts({
        page: 1,
        limit: 15,
        ...urlFilters
      }));
    }
  }, []);

  const handleFilterChange = (filterType, value) => {
    dispatch({
      type: SET_FILTER,
      payload: { filterType, value }
    });

    // Get current filters and update with new value
    const updatedFilters = {
      ...currentFilters,
      [filterType]: value,
      page: 1, // Reset to first page on filter change
    };

    dispatch(getNewFilterdProducts(updatedFilters));
  };

  const handleClearFilters = () => {
    const defaultFilters = {
      page: 1,
      limit: 15,
      color: [],
      // brandId: '',
      brands:[],
      modelId: [],
      category: [],
      storage: [],
      ram: [],
      discount: '',
      minPrice: '',
      maxPrice: ''
    };

    Object.entries(defaultFilters).forEach(([filterType, value]) => {
      dispatch({
        type: SET_FILTER,
        payload: { filterType, value }
      });
    });

    dispatch(getNewFilterdProducts(defaultFilters));
    
    // Clear URL params
    window.history.pushState({}, '', window.location.pathname);
  };


  return (
    <div className={clsx("sidebar-style", sideSpaceClass)}>
      <button onClick={handleClearFilters} style={{background:"#E32636",color:"white", borderRadius:"1vh", border:"1px solid white", padding:"1vh"}}>Clear All Filters</button>
      <ShopPriceRange
        priceRange={priceRange}
        // getSortParams={getSortParams}
        getSortParams={(type, value) => handleFilterChange(type, value)}
        selectedMinPrice={currentFilters.minPrice}
        selectedMaxPrice={currentFilters.maxPrice}
        currentFilters={currentFilters}
      />
      <MyShopCategory
        cat={uniqueCategory}
        // getSortParams={getSortParams}
        getSortParams={(value) => handleFilterChange('category', value)}
        selectedCategory={currentFilters.category}
        disc={uniqueDiscount}
        currentFilters={currentFilters}
      />
      <ShopTag
        // tags={uniqueBrands} 
        tags={brands} // Updated to brands
        models={models}
        // getSortParams={getSortParams}
        getSortParams={(type, value) => handleFilterChange(type, value)}
        selectedTag={currentFilters.brandId} // Updated to brandId
        selectedModel={currentFilters.modelId}
        currentFilters={currentFilters}
      />
      <ShopRam
        rams={uniqueRams}
        getSortParams={(value) => handleFilterChange('ram', value)}
        selectedRam={currentFilters.ram}
        currentFilters={currentFilters}
      />
      <ShopStorage
        storages={uniqueStorages}
        getSortParams={(value) => handleFilterChange('storage', value)}
        selectedStorage={currentFilters.storage}
        currentFilters={currentFilters}
      />
      <ShopColor
        colors={uniqueColors}
        // getSortParams={getSortParams}
        getSortParams={(value) => handleFilterChange('color', value)}
        selectedColor={currentFilters.color}
        currentFilters={currentFilters}
      />
    </div>
  );
};

MyShopSidebar.propTypes = {
  sideSpaceClass: PropTypes.string,
};

export default MyShopSidebar;

// Helper functions remain unchanged
export const getMyIndividualColors = (products) => {
  if (!Array.isArray(products)) return [];
  return Array.from(new Set(products.map((product) => product.color.toLowerCase())));
};

export const getMyIndividualRams = (products) => {
  if (!Array.isArray(products)) return [];
  return Array.from(new Set(products.map((product) => product.ram)));
};

export const getMyIndividualStorage = (products) => {
  if (!Array.isArray(products)) return [];
  return Array.from(new Set(products.map((product) => product.storage)));
};

export const getMyIndividualCategory = (products) => {
  if (!Array.isArray(products)) return [];
  return Array.from(new Set(products.map((product) => product.category.toLowerCase())));
};

export const getMyIndividualDiscount = (products) => {
  if (!Array.isArray(products)) return [];
  return Array.from(new Set(products.map((product) => product.disPer || 0)));
};

export const getMinMaxPrice = (products) => {
  if (!Array.isArray(products) || products.length === 0) return { min: 0, max: 1000 };
  const prices = products.map(product => product.price);
  return {
    min: Math.floor(Math.min(...prices)),
    max: Math.ceil(Math.max(...prices))
  };
};
