import {  GET_BANNER, GET_SLIDER_BANNER, GET_TESTIOMONIAL_BANNER } from "../types.js";

const initialState = {list: [], single: null, cities: []};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_BANNER:
            return {...state, list: payload};
        
        case GET_SLIDER_BANNER:
            return {...state, slider: payload};

        case GET_TESTIOMONIAL_BANNER:
            return {...state, testimonial: payload};

        default:
            return state;
    }
};

export default reducer;
