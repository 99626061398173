import { Fragment } from "react"; 
import { useLocation } from "react-router-dom"; 
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import TextGridOne from "../../wrappers/text-grid/TextGridOne";
import FunFactOne from "../../wrappers/fun-fact/FunFactOne";
// import TeamMemberOne from "../../wrappers/team-member/TeamMemberOne";
import { Typography,List  } from 'antd';


const { Title, Text  } = Typography;


const ReturnPolicy = ({settings}) => {
  let { pathname } = useLocation();
  
  return (
    <Fragment>
      <SEO
        titleTemplate="Privacy Policy"
        description="Privacy Policy of 09Mobile Gallery"
      /> 
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb 
          pages={[
            {label: "Home", path: process.env.PUBLIC_URL + "/" },
            {label: "Privacy Policy", path: process.env.PUBLIC_URL + pathname }
          ]} 
        />

        {/* section title with text */}
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Title level={2} style={{ fontSize: '2rem', margin: "2vh" }}>
            Return Policy
          </Title>
          <style jsx>{`
            @media (max-width: 600px) {
              h2 {
                font-size: 1.5rem; // Adjust for mobile
                }
                }
                `}</style>
        </div>
        <div style={{ padding: "5.5vh" }}>
          <Text style={{ fontSize: '1.2rem', margin: "5.5vh 0" }}>
          <Text>
            At 09Mobile Gallery, We want you to be completely satisfied with your purchase. If for any reason you are not, you may request a return within {settings[0].returnDay} days from the date of purchase. To initiate a return, please contact our support team at phone number : +91 {settings[0].ownerWpNo}.
          </Text>
          <br />
          <Text strong>Return Guidelines:</Text>
          <List
            bordered
            dataSource={[
              "Eligibility: Items must be in their same condition and packaging. This means they should be less unused, unaltered, and free from any damage.",
              "Process: Once you contact us, we will provide you with a return authorization and instructions for shipping the item back to us.",
              "Refunds: After we receive the returned item and verify its condition, we will process your refund to the original payment method within 5-7 business days."
            ]}
            renderItem={item => <List.Item>{item}</List.Item>}
          />
          <Text>
            We strive to ensure a seamless return process, but please note that shipping costs for returns are the responsibility of the customer unless the item is defective or incorrect.
          </Text>
          <Text>
            You have the right to access, correct, or delete your data by contacting us. This policy may be updated; any changes will be posted on our website. For more information or assistance, please reach out to our support team.
          </Text>
          </Text>
        </div>
        

        {/* banner */}
        {/* <BannerOne spaceBottomClass="pb-70" /> */}

        {/* text grid */}

        {/* fun fact */}
  

        {/* team member */}
        {/* <TeamMemberOne spaceTopClass="pt-95" spaceBottomClass="pb-70" settings={settings} /> */}

        {/* brand logo slider */}
      </LayoutOne>
    </Fragment>
  );
};

export default ReturnPolicy;
