import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SET_FILTER } from '../../store/types';
import { getNewFilterdProducts } from '../../store/actions/product-actions';

const ShopColor = ({ colors }) => {
  const dispatch = useDispatch();
  const currentFilters = useSelector(state => state.products.filter);

  const handleColorClick = (color) => {
    // Handle array of colors
    const updatedColors = currentFilters.color.includes(color)
      ? currentFilters.color.filter(c => c !== color) // Remove if exists
      : [...currentFilters.color, color]; // Add if doesn't exist

    // Update the filter in Redux
    dispatch({
      type: SET_FILTER,
      payload: {
        filterType: 'color',
        value: updatedColors
      }
    });
    
    // Fetch filtered products with updated colors
    dispatch(getNewFilterdProducts({
      ...currentFilters,
      page: 1,
      limit: 15,
      color: updatedColors
    }));
  };

  const handleClearColors = () => {
    dispatch({
      type: SET_FILTER,
      payload: {
        filterType: 'color',
        value: []
      }
    });

    dispatch(getNewFilterdProducts({
      ...currentFilters,
      page: 1,
      limit: 15,
      color: []
    }));
  };

  return (
    <div className="sidebar-widget mt-50">
      <h4 className="pro-sidebar-title">Color</h4>
      <div className="sidebar-widget-list mt-20">
        {colors ? (
          <ul>
            <li>
              <div className="sidebar-widget-list-left">
                <button
                  className={currentFilters.color.length === 0 ? 'active' : ''}
                  onClick={handleClearColors}
                >
                  <span className="checkmark" /> All Colors
                </button>
              </div>
            </li>
            {colors.map((color, key) => (
              <li key={key}>
                <div className="sidebar-widget-list-left">
                  <button
                    className={currentFilters.color.includes(color) ? 'active' : ''}
                    onClick={() => handleColorClick(color)}
                  >
                    <span className="checkmark" />
                    {color} {currentFilters.color.includes(color)}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          'No colors found'
        )}
      </div>
    </div>
  );
};

ShopColor.propTypes = {
  colors: PropTypes.array.isRequired,
};

export default ShopColor;