import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axios';
import { UPDATE_PROFILE_DATA } from '../types';
import Swal from 'sweetalert2';

export const loginUser = createAsyncThunk(
  'user/login',
  async (userData, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post('user/login', userData);
      if (res.data.code === 1) {
        console.log("res.data of login :", res.data.data.token);
        console.log("token :", res.data.data.token);
        
        localStorage.setItem('userauth', res.data.data.token);
        localStorage.setItem(
          'user',
          JSON.stringify({
            user: res.data.data.user,
          })
        );
        return {
          user: res.data.data.user,
          access_token: res.data.data.token,
        };
      } else {
        return rejectWithValue('Login failed');
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  'user/signup',
  async (userData, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post('user/signup', userData);
      // return response.data;
      if (res.data.code === 1) {
        localStorage.setItem('auth',res.data.data.token);
        return {
          user: res.data.data.user,
          successMessage: res.data.data.message
        };
      } else {
        return rejectWithValue(res?.data?.message || 'Registration failed');
        // return rejectWithValue('Login failed');
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Registration failed');
    }
  }
);

export const updateUserProfile = (userData) => async (dispatch) => {
  try {
    const res = await axiosInstance.post("user/update-profile", userData);
    console.log("res.data :", res.data);
    
    if (res.data.code === 1) {
      // Update local storage with new user data
      const existingUser = JSON.parse(localStorage.getItem("user"));
      const updatedUser = {
        ...existingUser,
        user: { ...existingUser.user, ...res.data.data }
      };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      
      dispatch({ type: UPDATE_PROFILE_DATA, payload: res.data.data });
      
      Swal.fire({
        icon: 'success',
        title: 'Profile Updated',
        text: 'Your profile has been successfully updated.'
      });
    }     
    // if (res.status === 400) {
    //   const errorMessage =
    //   res?.response.data?.message;

    // Swal.fire({
    //   title: "Error!",
    //   text: errorMessage,
    //   icon: "error",
    // });
    if (res.data.code === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: res.data.message
      });
    }
  } catch (err) {
    console.error('Failed to update profile:', err.message);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: err.message ||'An unexpected error occurred.'
    });
  }
};

// export const updateUserProfile = (userData) => async (dispatch) => {
//   try {
//     const res = await axiosInstance.post("user/update-profile", userData);
//     console.log("res.data :", res.data);
    
//     if (res.data.code === 1) {
//       // dispatch(setProd(res.data.data));
//       // dispatch(setFilteredProducts(res.data.data))
//        // Update local storage with new user data
//        const existingUser = JSON.parse(localStorage.getItem("user"));
//        const updatedUser = {
//          ...existingUser,
//          user: { ...existingUser.user, ...res.data.data }
//        };
//        localStorage.setItem("user", JSON.stringify(updatedUser));
//       dispatch({ type: UPDATE_PROFILE_DATA, payload: res.data.data });
//       Swal.fire({
//         icon: 'success',
//         title: 'Profile Updated',
//         text: 'Your profile has been successfully updated.'
//       });
//     } else {
//       // Handle case where the code is not 1 if needed
//       console.error('Unexpected response code:', res.data.code);
//     }
//     if (res.data.code === 0) {
//       console.log("hewre err");
      
//       Swal.fire({
//         icon: 'warning',
//         title: 'Warning!',
//         text: res.data.message
//       });
//     } else {
//       // Handle case where the code is not 1 if needed
//       console.error('Unexpected response code:', res.data.code);
//     }
//   } catch (err) {
//     console.error('Failed to fetch banners:', err.message);
//   }
// };
// export const registerUser = createAsyncThunk(
//   'user/signup',
//   async (userData, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post('user/signup', userData);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

