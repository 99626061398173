import { ADD_ORDERS, GET_ORDERS } from "../types";

const initialState = { list: [], single: null };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ORDERS:
            return { ...state, list: payload };

        case ADD_ORDERS:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        
        default:
            return state;
    }
};

export default reducer;
