import { ADD_CART, GET_CART, DELETE_CART } from "../types";

const initialState = { list: [], single: null };

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_CART:
            return { ...state, list: payload };

        case ADD_CART:
            return {
                ...state,
                list: [payload, ...state.list],
            };
        
        case DELETE_CART:
            return {
                ...state,
                list: state.list.filter((item) => item.id !== payload.id),
            };

        default:
            return state;
    }
};

export default reducer;
