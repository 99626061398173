import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Rating from "./sub-components/ProductRating";
import { getDiscountPrice } from "../../helpers/product";
// import ProductModal from "./ProductModal";
// import { addToCart } from "../../store/slices/cart-slice";
import { addToWishlist } from "../../store/slices/wishlist-slice";
import BestProductModal from "./BestProductModal";
import { addCart, getCartProducts } from "../../store/actions/cart-actions";

const BestProductGridSingle = ({
  product,
  currency,
  cartItem,
  // fastCartItems,
  wishlistItem,
  compareItem,
  spaceBottomClass
}) => {
  const [modalShow, setModalShow] = useState(false);

  const isNew = product.new || true;
  
  const discountedPrice = getDiscountPrice(product.price, product?.disPer || 0);
  const finalProductPrice = +(product.price);
  const finalDiscountedPrice = +(
    discountedPrice
  );

  
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize navigate
  // const isLoggedIn = useSelector((state) => state.user.user !== null); 
  const isLoggedIn = localStorage.getItem("userauth");
  console.log("islogged in :", isLoggedIn);
  

console.log("cart item : ", cartItem);


  // const videoId = product.youtubeUrl.split('/shorts/')[1]?.split('?')[0] || '';

  // const opts = {
  //   height: '390',
  //   width: '100%',
  //   playerVars: {
  //     autoplay: 1,
  //     mute: 1,
  //     loop: 1,
  //     playlist: videoId
  //   },
  // };

  // const onPlayerReady = (event) => {
  //   event.target.playVideo();
  // }
  

  const handleAddToCart = async () => {
    if (!isLoggedIn) {
      navigate("/login-register");
      return;
    }

    // Create the form data
    const formData = {
      productId: product._id
    };

    // Dispatch the addCart action with formData
    await dispatch(addCart(formData));
    await dispatch(getCartProducts());
    window.location.reload();
  };

  return (
    <Fragment>
      <div className={clsx("product-wrap", spaceBottomClass)}>
        <div className="product-img">
          <Link to={process.env.PUBLIC_URL + "/product/" + product._id}>
          {/* <YouTube videoId={videoId} opts={opts} onReady={onPlayerReady} /> */}
              <img
                className="default-img"
                src={product.images[0]?.src || "/assets/img/noimgavl.png"}
                style={{borderRadius:"2vh"}}
                width={390}
                height={290}
                alt=""
              />
            {product.images.length > 1 ? (
              <img
                className="hover-img"
                src={product.images[1].src}  // it needs to 1 but the first is video so i change to 0
                alt=""
                width={390}
                height={290}
                style={{borderRadius:"2vh"}}
              />
            ) : (
              ""
            )}
          </Link>
          {product.disPer || isNew ? (
            <div className="product-img-badges">
              {product.disPer ? (
                <span className="pink">-{product.disPer}%</span>
              ) : (
                ""
              )}
              {isNew ? <span className="purple">New</span> : ""}
            </div>
          ) : (
            ""
          )}

          <div className="product-action">
            <div className="pro-same-action pro-wishlist" style={{borderBottomLeftRadius: "2vh"}}>
              <button
                className={wishlistItem !== undefined ? "active" : ""}
                disabled={wishlistItem !== undefined}
                title={
                  wishlistItem !== undefined
                    ? "Added to wishlist"
                    : "Add to wishlist"
                }
                onClick={async () => await dispatch(addToWishlist(product))}
                
              >
                <i className="pe-7s-like" />
              </button>
            </div>
            <div className="pro-same-action pro-cart">
              {product.affiliateLink ? (
                <a
                  href={product.affiliateLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  Buy now{" "}
                </a>
              ) : product.variation && product.variation.length >= 1 ? (
                <Link to={`${process.env.PUBLIC_URL}/product/${product._id}`}>
                  Select Option
                </Link>
              ) : product.status && product.status > 0 ? (
                <button
                  // onClick={() => dispatch(addToCart(product))}
                  onClick={handleAddToCart}
                  className={
                    cartItem !== undefined
                      ? "active"
                      : ""
                  }
                  disabled={cartItem !== undefined}
                  title={
                    cartItem !== undefined ? "Added to bag" : "Add to bag"
                  }
                >
                  {" "}
                  <i className="pe-7s-cart"></i>{" "}
                  {cartItem !== undefined
                    ? "Added"
                    : "Add to bag"}
                </button>
              ) : (
                <button disabled className="active">
                  Out of Stock
                </button>
              )}
            </div>
            <div className="pro-same-action pro-quickview" style={{borderBottomRightRadius: "2vh"}}>
              <button title="Quick View" onClick={() => setModalShow(true)}>
                <i className="pe-7s-look" />
              </button>
            </div>
          </div>
        </div>
        <div className="product-content text-center">
          <h3>
            <Link to={process.env.PUBLIC_URL + "/product/" + product._id}>
              {product.name}
            </Link>
          </h3>
          {/* {product.rating && product.rating > 0 ? (
            <div className="product-rating">
              <Rating ratingValue={product.rating} />
            </div>
          ) : (
            ""
          )} */}
          <div className="product-price">
            {/* {discountedPrice !== null ? (
              <Fragment>
                <span>{"₹" + finalDiscountedPrice}</span>{" "}
                <span className="old">
                  {"₹" + finalProductPrice}
                </span>
              </Fragment>
            ) : (
              <span>{"₹" + finalProductPrice} </span>
            )} */}
            <span>
                    {"₹ "+product.price}
                  </span>{" "}
                  <span className="old">
                  {"₹ "+product.mrp}
                </span>
          </div>
        </div>
      </div>
      {/* product modal */}
      <BestProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        // bestprod={bestprod}
        // currency={currency}
        discountedPrice={discountedPrice}
        finalProductPrice={finalProductPrice}
        finalDiscountedPrice={finalDiscountedPrice}
        wishlistItem={wishlistItem}
        compareItem={compareItem}
      />
    </Fragment>
  );
};

BestProductGridSingle.propTypes = {
  // cartItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
  // wishlistItem: PropTypes.shape({}),
  // currency: PropTypes.shape({}),
  product: PropTypes.shape({}),
  bestprod: PropTypes.shape({}),
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
};

export default BestProductGridSingle;
