import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SET_FILTER } from '../../store/types';
import { getNewFilterdProducts } from '../../store/actions/product-actions';

const ShopRam = ({ rams }) => {
  const dispatch = useDispatch();
  const currentFilters = useSelector(state => state.products.filter);

  const handleRamClick = (ram) => {
    // Handle array of RAM values
    const updatedRams = currentFilters.ram.includes(ram)
      ? currentFilters.ram.filter(r => r !== ram) // Remove if exists
      : [...currentFilters.ram, ram]; // Add if doesn't exist

    // Update the filter in Redux
    dispatch({
      type: SET_FILTER,
      payload: {
        filterType: 'ram',
        value: updatedRams
      }
    });
    
    // Fetch filtered products with updated RAM selection
    dispatch(getNewFilterdProducts({
      ...currentFilters,
      page: 1,
      limit: 15,
      ram: updatedRams
    }));
  };

  const handleClearRams = () => {
    dispatch({
      type: SET_FILTER,
      payload: {
        filterType: 'ram',
        value: []
      }
    });

    dispatch(getNewFilterdProducts({
      ...currentFilters,
      page: 1,
      limit: 15,
      ram: []
    }));
  };

  return (
    <div className="sidebar-widget mt-50">
      <h4 className="pro-sidebar-title">Ram</h4>
      <div className="sidebar-widget-list mt-20">
        {rams ? (
          <ul>
            <li>
              <div className="sidebar-widget-list-left">
                <button
                  className={currentFilters.ram.length === 0 ? 'active' : ''}
                  onClick={handleClearRams}
                >
                  <span className="checkmark" /> All Rams
                </button>
              </div>
            </li>
            {rams.map((ram, key) => (
              <li key={key}>
                <div className="sidebar-widget-list-left">
                  <button
                    className={currentFilters.ram.includes(ram) ? 'active' : ''}
                    onClick={() => handleRamClick(ram)}
                  >
                    <span className="checkmark" /> {ram} {currentFilters.ram.includes(ram)}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          'No rams found'
        )}
      </div>
    </div>
  );
};

ShopRam.propTypes = {
  rams: PropTypes.array.isRequired,
};

export default ShopRam;