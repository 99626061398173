import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import debounce from "lodash/debounce"; // You may need to install lodash
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import MyShopSidebar from "../../wrappers/product/MyShopSidebar";
import ShopTopbar from "../../wrappers/product/ShopTopbar";
import ShopProducts from "../../wrappers/product/ShopProducts";
import { getNewFilterdProducts } from "../../store/actions/product-actions";

const MyShopGridStandard = () => {
  const [layout, setLayout] = useState("grid three-column");
  const [currentPage, setCurrentPage] = useState(1);
  const PAGE_LIMIT = 15; // Changed to a constant
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const filteredProducts = useSelector((state) => state.products.filter || []);

  const filteredProducts = useSelector(state => state.products.filteredProducts || []);
  console.log("filteredProducts :", filteredProducts);
  

  // Memoize the fetch function
  // const fetchProducts = useCallback(async () => {
  //     setLoading(true);
  //     setError(null);
  //     try {
  //         await dispatch(getNewFilterdProducts({
  //             page: currentPage,
  //             limit: PAGE_LIMIT,
  //             brandId: currentFilters.brand,
  //             category: currentFilters.category,
  //             disPer: currentFilters.discount,
  //             color: currentFilters.color
  //         }));
  //     } catch (err) {
  //         console.error('Failed to fetch products:', err);
  //         setError('Failed to load products. Please try again later.');
  //     } finally {
  //         setLoading(false);
  //     }
  // }, [dispatch, currentPage, currentFilters]);

  // // Debounce the fetch function
  // const debouncedFetch = useMemo(
  //     () => debounce(fetchProducts, 300),
  //     [fetchProducts]
  // );

  // useEffect(() => {
  //     debouncedFetch();
      
  //     // Cleanup
  //     return () => {
  //         debouncedFetch.cancel();
  //     };
  // }, [debouncedFetch]);

  const handlePageChange = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const handleSortChange = useCallback((sortType, sortValue) => {
    console.log("Sort type:", sortType, "Sort value:", sortValue);
  }, []);

  // Memoize the pagination buttons
  const paginationButtons = useMemo(() => {
    const pageCount = Math.ceil(filteredProducts.length / PAGE_LIMIT);
    return Array.from({ length: pageCount }, (_, i) => (
      <li key={i} className={currentPage === i + 1 ? "active" : ""}>
        <button onClick={() => handlePageChange(i + 1)}>{i + 1}</button>
      </li>
    ));
  }, [filteredProducts.length, currentPage, handlePageChange]);

  // Memoize components that don't need frequent re-renders
  const MemoizedShopTopbar = useMemo(
    () => (
      <ShopTopbar
        getLayout={setLayout}
        productCount={filteredProducts.length}
        sortedProductCount={filteredProducts.length}
        getFilterSortParams={handleSortChange}
      />
    ),
    [filteredProducts.length, handleSortChange]
  );

  const MemoizedShopProducts = useMemo(
    () => <ShopProducts layout={layout} products={filteredProducts} />,
    [layout, filteredProducts]
  );

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <React.Fragment>
      <SEO
        titleTemplate="Shop Page"
        description="Shop page of eCommerce template."
      />
      <LayoutOne headerTop="visible">
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            { label: "Shop", path: process.env.PUBLIC_URL + "/" },
          ]}
        />
        <div className="shop-area pt-95 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 order-2 order-lg-1">
                <MyShopSidebar sideSpaceClass="mr-30" />
              </div>
              <div className="col-lg-9 order-1 order-lg-2">
                {MemoizedShopTopbar}
                {loading && (
                  <div className="text-center mb-3">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                )}
                {MemoizedShopProducts}
                {filteredProducts.length > 0 && (
                  <div className="pro-pagination-style text-center mt-30">
                    <ul>{paginationButtons}</ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </React.Fragment>
  );
};

export default React.memo(MyShopGridStandard);
