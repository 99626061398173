import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getNewFilterdProducts } from "../../store/actions/product-actions";
import { SET_FILTER } from "../../store/types";

const ShopBrand = ({ tags, models, getSortParams }) => {
  const dispatch = useDispatch();
  const currentFilters = useSelector((state) => state.products.filter);

  const handleBrandClick = (brandId) => {
    let newBrands = [...(currentFilters.brands || [])];
    
    if (newBrands.includes(brandId)) {
      newBrands = newBrands.filter(id => id !== brandId);
    } else {
      newBrands.push(brandId);
    }

    dispatch({
      type: SET_FILTER,
      payload: { filterType: "brands", value: newBrands },
    });

    // Clear model selection when brands change
    dispatch({
      type: SET_FILTER,
      payload: { filterType: "modelId", value: "" }, // Keep this as a string
    });

    dispatch(
      getNewFilterdProducts({
        ...currentFilters,
        page: 1,
        limit: 15,
        brands: newBrands,
        modelId: "", // Reset to empty string
      })
    );
  };

  // const handleModelClick = (modelId) => {
  //   let selectedModels = currentFilters.modelId ? currentFilters.modelId.split(',') : [];
    
  //   if (selectedModels.includes(modelId)) {
  //     selectedModels = selectedModels.filter(id => id !== modelId);
  //   } else {
  //     selectedModels.push(modelId);
  //   }

  //   const newModelId = selectedModels.join(','); // Convert back to a string

  //   dispatch({
  //     type: SET_FILTER,
  //     payload: { filterType: "modelId", value: newModelId }, // Dispatch the comma-separated string
  //   });

  //   dispatch(
  //     getNewFilterdProducts({
  //       ...currentFilters,
  //       page: 1,
  //       limit: 15,
  //       modelId: newModelId, // Use the comma-separated string
  //     })
  //   );

  //   getSortParams("modelId", newModelId);
  // };

  const handleModelClick = (modelId) => {
    if (modelId === '') {
      // Clear model selection when "All Models" is clicked
      dispatch({
        type: SET_FILTER,
        payload: { filterType: "modelId", value: "" }, // Reset to empty string
      });
  
      dispatch(
        getNewFilterdProducts({
          ...currentFilters,
          page: 1,
          limit: 15,
          modelId: "", // Reset to empty string
        })
      );
  
      getSortParams("modelId", ""); // Pass empty string
    } else {
      // Handle single model selection
      let selectedModels = currentFilters.modelId ? currentFilters.modelId.split(',') : [];
  
      if (selectedModels.includes(modelId)) {
        selectedModels = selectedModels.filter(id => id !== modelId);
      } else {
        selectedModels.push(modelId);
      }
  
      const newModelId = selectedModels.join(',');
  
      dispatch({
        type: SET_FILTER,
        payload: { filterType: "modelId", value: newModelId },
      });
  
      dispatch(
        getNewFilterdProducts({
          ...currentFilters,
          page: 1,
          limit: 15,
          modelId: newModelId,
        })
      );
  
      getSortParams("modelId", newModelId);
    }
  };
  

  // Show models if any brand is selected
  const filteredModels = currentFilters.brands?.length > 0
    ? models.filter((model) => currentFilters.brands.includes(model.brandId))
    : [];

  return (
    <>
      <div className="sidebar-widget mt-50">
        <h4 className="pro-sidebar-title">Brand</h4>
        <div className="sidebar-widget-list mt-20">
          {tags ? (
            <ul>
              <li>
                <div className="sidebar-widget-list-left">
                  <button
                    className={currentFilters.brands?.length === 0 ? 'active' : ''}
                    onClick={() => {
                      dispatch({
                        type: SET_FILTER,
                        payload: { filterType: "brands", value: [] },
                      });
                      dispatch(
                        getNewFilterdProducts({
                          ...currentFilters,
                          brands: [],
                          modelId: "",
                        })
                      );
                    }}
                  >
                    <span className="checkmark" /> All Brands
                  </button>
                </div>
              </li>
              {tags.map((brand) => (
                <li key={brand._id}>
                  <div className="sidebar-widget-list-left">
                    <button
                      className={currentFilters.brands?.includes(brand._id) ? 'active' : ''}
                      onClick={() => handleBrandClick(brand._id)}
                    >
                      <span className="checkmark" /> {brand.name}
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            'No brands found'
          )}
        </div>
      </div>

      {currentFilters.brands?.length > 0 && (
        <div className="sidebar-widget mt-50">
          <h4 className="pro-sidebar-title">Models</h4>
          <div className="sidebar-widget-list mt-20">
            {filteredModels.length > 0 ? (
              <ul>
                <li>
                  <div className="sidebar-widget-list-left">
                    <button
                      className={currentFilters.modelId === '' ? 'active' : ''}
                      onClick={() => handleModelClick('')}
                    >
                      <span className="checkmark" /> All Models
                    </button>
                  </div>
                </li>
                {filteredModels.map((model) => (
                  <li key={model._id}>
                    <div className="sidebar-widget-list-left">
                      <button
                        className={currentFilters.modelId.split(',').includes(model._id) ? 'active' : ''}
                        onClick={() => handleModelClick(model._id)}
                      >
                        <span className="checkmark" /> {model.name}
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              'No models found for the selected brands'
            )}
          </div>
        </div>
      )}
    </>
  );
};

ShopBrand.propTypes = {
  tags: PropTypes.array.isRequired,
  models: PropTypes.array.isRequired,
  getSortParams: PropTypes.func.isRequired,
};

export default ShopBrand;