import axios from "../../axios";

import {
  GET_SETTINGS,
} from "../types";
import Swal from 'sweetalert2';

// Get getSettings
export const getSettings = () => async (dispatch) => {
  try {
    const res = await axios.get("sett/get");
    if (res.data.code === 1) {
      dispatch({ type: GET_SETTINGS, payload: res.data.data });
    }
  } catch (err) {
    Swal.fire({
      title: 'Error!',
      text: 'Could not fetch settings.',
      icon: 'error',
    });
  }
};





