import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getNewFilterdProducts } from '../../store/actions/product-actions';
import { SET_FILTER } from '../../store/types';

const MyShopCategory = () => {
  const categories = ['Mobile', 'Tablet', 'Watch', 'Airpod', 'Accessories'];
  const dispatch = useDispatch();
  const currentFilters = useSelector(state => state.products.filter);

  const handleCategoryClick = (category) => {
    // Handle array of categories
    const updatedCategories = currentFilters.category.includes(category)
      ? currentFilters.category.filter(cat => cat !== category) // Remove if exists
      : [...currentFilters.category, category]; // Add if doesn't exist

    // Update the filter in Redux
    dispatch({
      type: SET_FILTER,
      payload: {
        filterType: 'category',
        value: updatedCategories
      }
    });
    
    // Fetch filtered products with updated categories
    dispatch(getNewFilterdProducts({
      ...currentFilters,
      page: 1,
      limit: 15,
      category: updatedCategories
    }));
  };

  const handleClearCategories = () => {
    dispatch({
      type: SET_FILTER,
      payload: {
        filterType: 'category',
        value: []
      }
    });

    dispatch(getNewFilterdProducts({
      ...currentFilters,
      page: 1,
      limit: 15,
      category: []
    }));
  };

  return (
    <div className="sidebar-widget mt-40">
      <h4 className="pro-sidebar-title">Category</h4>
      <div className="sidebar-widget-list mt-20">
        <ul>
          <li>
            <div className="sidebar-widget-list-left">
              <button
                className={currentFilters.category.length === 0 ? 'active' : ''}
                onClick={handleClearCategories}
              >
                <span className="checkmark" /> All Categories
              </button>
            </div>
          </li>
          {categories.map((category, key) => (
            <li key={key}>
              <div className="sidebar-widget-list-left">
                <button
                  className={currentFilters.category.includes(category) ? 'active' : ''}
                  onClick={() => handleCategoryClick(category)}
                >
                  <span className="checkmark" />
                  {category} {currentFilters.category.includes(category)}
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MyShopCategory;