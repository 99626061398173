import { Fragment, useState } from "react"; 
import { useLocation, useNavigate } from "react-router-dom"; 
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import TextGridOne from "../../wrappers/text-grid/TextGridOne";
import FunFactOne from "../../wrappers/fun-fact/FunFactOne";
// import TeamMemberOne from "../../wrappers/team-member/TeamMemberOne";
import { Typography } from 'antd';
import Swal from "sweetalert2";
import axios from "axios";


const { Title, Text  } = Typography;


const ForgotPassword = () => {
  let { pathname } = useLocation();
  const [mobile, setmobile] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false); // Track if OTP is sent
  const [otpError, setOtpError] = useState(false); // Track if OTP is incorrect
  const navigate = useNavigate();

  const handleGetOTP = async () => {
      // Validate mobile number
      if (!mobile) {
          Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Please enter your mobile number.',
          });
          return;
      }

      try {
          const response = await axios.post('http://192.168.29.244:3030/api/forgot-password', { mobile });

          if (response.status === 200) {
              Swal.fire({
                  icon: 'success',
                  title: 'OTP Sent',
                  text: 'An OTP has been sent to your mobile number.',
              });
              setIsOtpSent(true); // Set OTP sent flag
          } else {
              Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Failed to send OTP. Please try again.',
              });
          }
      } catch (error) {
          console.error("Error during OTP request:", error); 
          Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response ? error.response.data.message : 'An error occurred while sending OTP. Please try again later.',
          });
      }
  };

  const handleResetPassword = async () => {
      // Validate OTP and new password
      if (!otp || !newPassword) {
          Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Please enter both OTP and new password.',
          });
          return;
      }

      try {
          const response = await axios.post('http://192.168.29.244:3030/api/reset-password', { mobile, otp, newPassword });

          if (response.status === 200) {
              Swal.fire({
                  icon: 'success',
                  title: 'Password Reset Successful',
                  text: 'Your password has been reset successfully.',
              });
              navigate('/login-register')
          } else {
              Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Invalid OTP or something went wrong. Please try again.',
              });
              setOtpError(true); // Set OTP error flag if OTP is invalid
          }
      } catch (error) {
          console.error("Error during password reset:", error); // Log the error to the console for debugging
          Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response ? error.response.data.message : 'An error occurred while resetting the password. Please try again later.',
          });
      }
  };
  
  return (
    <Fragment>
      <SEO
        titleTemplate="Forgot Password"
        description="Forgot Password of 09Mobile Gallery"
      /> 
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb 
          pages={[
            {label: "Home", path: process.env.PUBLIC_URL + "/" },
            {label: "Forgot Password", path: process.env.PUBLIC_URL + pathname }
          ]} 
        />

        {/* section title with text */}
        {/* <div style={{ textAlign: 'center', padding: '20px' }}>
          <Title level={2} style={{ fontSize: '2rem', margin: "2vh" }}>
           Forgot Password
          </Title>
          <style jsx>{`
            @media (max-width: 600px) {
              h2 {
                font-size: 1.5rem; // Adjust for mobile
                }
                }
                `}</style>
        </div> */}
        <div style={{ padding: "5.5vh" }}>
        <>
            <div className="forgot-password-container d-flex justify-content-center align-items-center">
                <div className="form-wrapper">
                    <h2>{isOtpSent ? 'Reset Password' : 'Forgot Password'}</h2>
                    <form onSubmit={(e) => e.preventDefault()}>
                        {!isOtpSent ? (
                            <>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Mobile Number"
                                        value={mobile}
                                        onChange={(e) => setmobile(e.target.value)}
                                        required
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary w-100"
                                    onClick={handleGetOTP}
                                >
                                    Get OTP
                                </button>
                            </>
                        ) : (
                            <>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Mobile Number"
                                        value={mobile}
                                        disabled
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter OTP"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        required
                                    />
                                    {otpError && (
                                        <small className="text-danger">Invalid OTP. Please try again.</small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="New Password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-primary w-100"
                                    onClick={handleResetPassword}
                                >
                                    Reset Password
                                </button>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default ForgotPassword;




